<template>
    <LeaveOfAbsence clientCode="DISCOVER" :displayNewSurvey="true"></LeaveOfAbsence>
</template>
<script>
import LeaveOfAbsence from "@/components/shared/LOA/LeaveOfAbsence";
require('@/assets/css/client/loa/variables.scss')
require('@/assets/css/client/Discover/discover_variables.scss');

export default {
  components: { LeaveOfAbsence },
  name: "DiscoverLeaveOfAbsence",
};
</script>
<style lang="scss" scoped src="@/assets/css/client/loa/checklist_styling.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/loa/sample_language.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/loa/bar_chart.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/loa/feedback_modal.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/loa/send_email_modal.scss"></style>
<style lang="scss" scoped src="@/assets/css/client/loa/style.scss"></style>
<!-- Include last to override any generic styling -->
<style lang="scss" scoped src="@/assets/css/client/Discover/style.scss"></style>